<template>
  <v-card id="cw-confirmation-dialog--dialog__guarantor">
    <v-card-title id="cw-confirmation-dialog__title" class="text-h5">
      <translate>
        Acting as a guarantor
      </translate>
    </v-card-title>
    <v-card-text id="cw-confirmation-dialog__text">
      <p v-translate>
        The loan applicant also acts as a guarantor of the loan. When a loan has a personal
        guarantee, no real collateral is required from the company and the loan decision can be
        made at the end of the loan application.
      </p>
      <p v-translate class="ma-0">
        The guarantor is also the contact person between the company and the lender in matters
        related to the management of the loan and can handle matters related to invoicing, for
        example.
      </p>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        id="cw-confirmation-dialog__confirm"
        :loading="$wait.waiting('confirmation_dialog__confirm_processing')"
        :disabled="$wait.waiting('confirmation_dialog__confirm_processing')"
        color="primary"
        text
        @click="
          $emit('dialog-cancel');
          $eventLogger.clickEvent($event);
        "
      >
        <translate>
          Close
        </translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'CwDialogGuarantor',
};
</script>
